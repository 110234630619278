<template>
  <div id="video-details">

    <!-- desktop -->
    <div class="px-2" v-if="$vuetify.breakpoint.mdAndUp">
      <div class="d-flex align-center">
        <v-avatar class="mr-1 my-1" :style="Object.hasOwnProperty.call(video,'bg') ? `background-color:${video.bg}` : 'white'"
        @click="$router.push({name:'userPage', params: {userId: video.userId}})"
        >
          <v-img :src="video.avatar"></v-img>
        </v-avatar>
        <div class="flex-grow-1 ml-2">
          <h3 class="video-title">{{video.title}}</h3>
          <div class="d-flex align-center justify-space-between">
            <div class="d-flex align-center justify-space-between">
              {{video.channelTitle}} by <v-btn small text color="primary" class="pa-1" @click="$router.push({name:'userPage', params: {userId: video.userId}})">{{video.displayName}}</v-btn>
              <!-- <span class="mx-2 grey--text">|</span>
              {{video.steps.length}} steps
              <span class="mx-2 grey--text">|</span>
              {{$getDuration(video.duration)}} -->
            </div>
            <div class="d-flex align-center justify-end col-sm-12 col-xs-12 col-md-6 pa-0">
              <!-- share -->
              <v-btn  text small color="" class="ma-0 mr-1 pa-0 cap " @click="shareDialog = true">
                <v-icon class="ml-1 flip">reply</v-icon> Share
              </v-btn>

              <!-- video -->
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" icon color="error darken-1" class="ma-0 pa-0 cap" :href="`https://www.youtube.com/watch?v=${video.videoId}`" target="_blank">
                    <v-icon class="ml-1">ondemand_video</v-icon>
                  </v-btn>
                </template>
                <span>Youtube Video</span>
              </v-tooltip>
              <!-- channel -->
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" icon color="error darken-1" class="ma-0 pa-0 cap" :href="`https://www.youtube.com/channel/${video.channelId}`" target="_blank">
                    <v-icon class="ml-1">subscriptions</v-icon>
                  </v-btn>
                </template>
                <span>Youtube Channel</span>
              </v-tooltip>
            </div>


          </div>
        </div>
      </div>
      <hr style="opacity:.6">
      <!-- <video-details-field :label="'channel title'" :text="video.channelTitle"></video-details-field> -->
      <video-details-field  :text="video.description" :parse="true"></video-details-field>
    </div>


    <div class=" details pa-1" v-else>
        <div class="text-truncate" style="max-width:94%">
          <strong>{{video.title}}</strong>
        </div>

      <div class=" d-flex align-center justify-space-between" >

        <div class="pa-0">
          {{video.channelTitle}} by  <v-btn small text color="primary" @click="$router.push({name:'userPage', params: {userId: video.userId}})">{{video.displayName}}</v-btn>
        </div>

        <div class="flex-auto pa-0 d-flex align-center justify-end">
          <v-menu
              content-class="channel-menu"
              transition="slide-x-transition"
              bottom
              right
              style="background:white"
            >
              <template v-slot:activator="{ on }">
                <v-btn icon v-on="on" class="pa-2" :class="{'mr-4': $vuetify.breakpoint.smAndDown}">
                  <v-icon>more_horiz</v-icon>
                </v-btn>
              </template>

                <v-btn block color="white" class="ma-0 pa-0" @click="shareDialog = true">
                  <v-icon class="ml-1 flip">reply</v-icon> Share
                </v-btn>

                <v-btn block class="ma-0 pa-0" :href="`https://www.youtube.com/watch?v=${video.videoId}`" target="_blank">
                  <v-icon color="error darken-1" class="mr-1">ondemand_video</v-icon>
                  Video
                </v-btn>

                <v-divider></v-divider>

                <v-btn color="white" block class="ma-0 pa-0" :href="`https://www.youtube.com/channel/${video.channelId}`" target="_blank">
                  <v-icon color="error darken-1" class="mr-1">subscriptions</v-icon>
                  Youtube Channel
                </v-btn>


            </v-menu>
        </div>
      </div>

    </div>




    <v-dialog v-model="shareDialog" max-width="300px">
          <v-card>
            <v-card-title class="gradientBG white--text">Share This Youtorial</v-card-title>

            <v-card-text>
              <div class="py-2">Shareable URL</div>
              <v-text-field
                hide-details
                filled
                :value="`https://watchyoutorials.com/watch/${video['.key']}`"
                @focus="selectAll"
                readonly></v-text-field>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary"  @click="shareDialog = false">Close</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>




  </div>
</template>

<script>
const videoDetailsField = () => import('@/components/shared/video-details-field.vue')

export default {
  name: "video-details",
  props: ['build','video'],
  components:{videoDetailsField},
  data(){
    return{
      shareDialog: false,
    }
  },
  watch:{

  },
  methods: {
    selectAll(e){
      // console.log('selected', e.target);
      e.target.select()
      // this.$refs.timer.select();
      // @focus.native="$event.target.select()"
    },
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.channel-menu{
  padding: 6px;
  background: white;
  .v-btn{
    justify-content: start;
    text-transform: capitalize;
    box-shadow: none;
    border-radius: 0;
  }
}
#video-details{
  .details{
      font-size: 15px;
      .strong{
        font-size: 14px;
      }
  }
  .video-title{
    font-size: 1rem;
    line-height: .6rem;
  }
  // .time-input{
  //     width: 140px;
  // }
  // .v-input__slot{
  //   margin:0;
  //   padding: 0 8px;
  //   .v-input__prepend-inner{
  //     margin-top: 8px!important;
  //   }
  //   input{
  //     font-size: 24px;
  //   }
  // }
  // .v-text-field__details{
  //   display: none;
  // }
  .flip{
    -moz-transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
    -o-transform: scaleX(-1);
    transform: scaleX(-1);
    -ms-filter: fliph; /*IE*/
    filter: fliph; /*IE*/
  }
}
</style>
